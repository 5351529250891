<template>
  <div class="active-content">
     <div class="header"></div>
    <div class="content">
       <div :class="'content-'+(index+1)" v-for="(item,index) in 3" :key="index">
         <div class="content-button" @click="getLottery(item)" v-track="{event:pointArr[index],page:'抽奖活动页面'}">
           <img class="img-button" v-show="!isGetCoupon || index !== 2 " src="../img/button.png" alt="">
           <img class="img-button" v-show="isGetCoupon && index === 2" src="../img/button_gray.png" alt="">
         </div>
         <div class="content-rules" @click="getRules(item)">
           <img class="img-rules" src="../img/content-ruler.png" alt="">
         </div>
         <div class="coupon" v-if="item===3">
           <img class="img-coupon" v-if="!isGetCoupon" src="../img/coupon.png" alt="">
           <img class="img-coupon" v-if="isGetCoupon" src="../img/coupon-get.png" alt="">
         </div>
       </div>
      <div class="content-4" @click="openClient" v-track="{event:'咨询客服',page:'抽奖活动页面'}"/>

      <div class="content-5">
        <div class="lottery" >
          <lottery class="lottery-content" ref="lottery"></lottery>
        </div>
        <img class="content-5-img" src="../img/recodes.png" alt="" @click="openRecodes" v-track="{event:'抽奖记录',page:'抽奖活动页面'}">
      </div>


      <div class="content-6">
        <img class="content-6-button" @click="openClient" src="../img/bottom-button.png" alt="" v-track="{event:'咨询客服',page:'抽奖活动页面'}">
      </div>
    </div>

	  <fillout-dialog v-if="centerDialogVisible" @close="closeDialog" @find="getCouponList(true)"></fillout-dialog>

  </div>
</template>

<script setup>
import emitter from "@/utils/eventBus";
import {receiveList,couponReceive,updateNickName}  from '@/api/activity'
import Lottery from './lottery.vue'
import { ref, onMounted, reactive, nextTick } from "vue";
import FilloutDialog from "@/views/activity/components/fillout-dialog.vue";
import {ElMessage} from "element-plus";
import {getIfLogin} from "@/api/login";

const lottery=ref(null)
//参与活动的八张优惠券
const couponStr='FREE202412310001,FREE202412310002,FREE202412310003,FREE202412310004,FREE202412310005,FREE202412310006,FREE202412310007,FREE202412310008'
const couponList=ref([])
const isGetCoupon=ref(false)
const centerDialogVisible=ref(false)

const loading=ref(false)
const rulesTop=ref(0)
const pointArr=['注册领红包','咨询领红包','一键领券']

onMounted(()=>{
  //获取活动说明距离顶部的高度
  nextTick(()=>{
    rulesTop.value= document.querySelector('.content-6').offsetTop
  })

  setTimeout(()=>{
    getPoint()
  },2000)
})

//关闭公司名称填写弹窗
const closeDialog=()=>{
	centerDialogVisible.value=false
}

//查询是否领取了优惠券
const hasGetCoupon=()=>{
  return new Promise((resolve,reject)=>{
    couponReceive({couponList: couponStr}).then(res=>{
      if (res.data>0){
        isGetCoupon.value=true
        resolve()
      }else{
        isGetCoupon.value=false
        reject()
      }
    })
  })
}

//领取多张优惠券
const getMoreCouponList=()=>{
  return new Promise((resolve,reject)=>{
    receiveList(couponStr).then(res=> {
      if (res.message === '请先填写公司名称') {
        reject()
      }else{
        if (res.message==='success'){
          // 领取成功
          ElMessage({
            message: '领取成功',
            type: 'success',
          })
        }else{
          ElMessage({
            message: res.message,
            type: 'warning'
          })
        }
        resolve()
      }
    })
  })
}


//领取多张优惠券
const getCouponList=(isNotCompanyName)=>{
  if (loading.value){
    return
  }
  loading.value=true

  if (!isNotCompanyName){
    hasGetCoupon().then(()=>{
      // 领取成功
      ElMessage({
        message: '您已领取过该优惠券',
        type: 'error',
      })
    }).catch(()=>{
      getMoreCouponList().then(()=>{
        hasGetCoupon()
      }).catch(()=>{
        centerDialogVisible.value=true
      })
    })
  }else{
    getMoreCouponList().then(()=>{
      hasGetCoupon()
    }).catch(()=>{
      centerDialogVisible.value=true
    })
  }




  setTimeout(()=>{
    loading.value=false
  },2000)
}

//判断是否登录
const isLogin=()=>{
  return new Promise((resolve,reject)=>{
    getIfLogin().then((res) => {
      if (res.code !== 200) {
        if (localStorage.getItem("token")) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        }
        emitter.emit('openLogin',{ openLogin:  true})
        reject()
      }else{
        resolve()
      }
    });
  })
}


//立即领取
const getLottery=(item)=>{
  if (item === 1){
    isLogin().then(res=>{
      emitter.emit("onlineClient")
    })
  }else if(item === 2){
	  emitter.emit("onlineClient")
  }else{
    if (isGetCoupon.value){
      return
    }
    isLogin().then(()=>{
      getCouponList(false)
    })
  }
}

//打开抽奖记录
const openRecodes=()=>{
  isLogin().then(res=>{
    lottery.value.isShowRecodes=true
  })

}


//咨询客服
const openClient=()=>{
  emitter.emit("onlineClient")
}

//活动规则
const getRules=(item)=>{
  window.scrollTo({ left: 0, top: rulesTop.value, behavior: 'smooth' })
}

window.addEventListener('resize',()=>{
  nextTick(()=>{
    rulesTop.value= document.querySelector('.content-6').offsetTop
    getPoint()
  })
})


//转盘中心点手型
const getPoint=()=>{
  let canvas=document.querySelector('.lottery-content')?.childNodes[0]?.childNodes[0]

  // Canvas中心点坐标
  const centerX = canvas.width / 2;
  const centerY = canvas.height / 2;

  const centerRadiusRem = 3.25;

  // 将 rem 转换为 px
  const centerRadius = centerRadiusRem * parseFloat(getComputedStyle(document.documentElement).fontSize);

  // 判断鼠标是否在中心区域内
  function isMouseInCenterArea(x, y) {
    const dx = x - centerX;
    const dy = y - centerY;
    return Math.sqrt(dx * dx + dy * dy) <= centerRadius;
  }

  // 监听鼠标移动事件
  canvas.addEventListener('mousemove', (e) => {
    const mouseX = e.offsetX;
    const mouseY = e.offsetY;

    // 如果鼠标在中心区域内，设置手型
    if (isMouseInCenterArea(mouseX, mouseY)) {
      canvas.style.cursor = 'pointer';
    } else {
      canvas.style.cursor = 'default';
    }
  });
}



</script>

<style scoped lang="less">
.public-content(@url,@height,@y){
  width: 1266px;
  height: @height;
  background: url(@url) no-repeat;
  background-size: 100%;
  position: relative;
  .content-button{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,@y);
    .img-button{
      width: 510px;
      height: 160px;
      cursor: pointer;
    }

  }
  .content-rules{
    position: absolute;
    top: 87px;
    right: 108px;
    .img-rules{
      width: 171px;
      height: 41px;
      cursor: pointer;
    }
  }
  .coupon{
    position: absolute;
    left: 82px;
    top: 476px;
    .img-coupon{
      width: 1101px;
      height: 290px;
    }
  }
}

.active-content{
  width: 100%;
  height: 100%;

  .header{
    width: 100%;
    height: 1080px;
    background: url("../img/head.png") no-repeat;
    background-size: 100%;
  }
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background: rgba(255,143,73);
    width: 100%;

    .content-1{
      .public-content('../img/content-1.png',604px,15%)
    }
    .content-2{
      .public-content('../img/content-2.png',604px,15%)
    }
    .content-3{
      .public-content('../img/content-3.png',920px,25%)
    }
    .content-4{
       width: 1266px;
       height: 325px;
       background: url("../img/content-4.png");
       background-size: 100%;
       cursor: pointer;
    }

    .content-5{
      width: 1266px;
      height: 880px;
      background: url("../img/content-5.png");
      background-size: 100%;
      position: relative;
      .lottery{
        width: 544px;
        height: 667px;
        position: absolute;
        background: url("../img/bg.png");
        background-size: 100%;


        right: 122px;
        top: 165px;
       .lottery-content{
         width: 100%;
       }
      }
      .content-5-img{
        width: 135px;
        height: 41px;
        position: absolute;
        cursor: pointer;
        right: 108px;
        top: 66px;
      }
    }
    .content-6{
      width: 1266px;
      height: 1130px;
      background: url('../img/content-6.png') no-repeat;
      background-size: 100%;
      position: relative;

      .content-6-button{
        width: 350px;
        height: 73px;
        position: absolute;
        left: 50%;
        bottom: 122px;
        transform: translateX(-50%);
        cursor: pointer;
      }

    }

  }
}
.dialog-box{
  width: 100%;
  height: 100px;
}
</style>
