<template>
  <div v-if="isVisible" class="overlay" @click="close">
    <div class="modal-content">
      <div class="lottery-dialog-box">
         <div class="close" @click="close"></div>
        <div class="recodes">
          <div class="recodes-head">
            <div>抽奖时间</div>
            <div>是否中奖</div>
            <div>抽中奖品</div>
          </div>
          <div class="recodes-content scrollable" >
            <div class="recodes-box">
              <div class="recodes-item" v-for="(item,index) in orderLists" :key="index">
                <div>{{item.drawTime}}</div>
                <div>{{item.isJackpot}}</div>
                <div>{{item.prizeName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
// 控制遮罩显示/隐藏
import { ref,onMounted } from "vue";
import {lotteryRecords} from "@/api/lottery"

const isVisible = ref(true);
const emits=defineEmits(['close'])
const props=defineProps({
  activityNumber:String
})
const orderLists=ref([])

// 在组件挂载时禁用页面滚动
onMounted(() => {
  document.body.style.overflow = 'hidden';

  lotteryRecords({activityNumber:props.activityNumber}).then(res=>{
    let data= res.data.list
    data.forEach(v=>{
      v.isJackpot = Number(v.isJackpot)===0?'否':'是'
      if (v.prizeName.indexOf('元')!==-1){
        v.prizeName=v.prizeName+'无门槛优惠券'
      }
    })
    orderLists.value=data
  })
});




// 关闭遮罩函数
const close = () => {
  isVisible.value = false;
  document.body.style.overflow = '';
  emits('close')
};
</script>

<style scoped lang="less">
/* 全屏遮罩 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


/* 弹窗内容 */
.modal-content {
  text-align: center;
  position: relative;
 .lottery-dialog-box{
   background: url("../img/recode-box.png") no-repeat;
   width: 1083px;
   height: 483px;
   background-size: 100%;
   position: relative;
   padding-top: 90px;

   .close{
     position: absolute;
     width: 24px;
     height: 24px;
     background: url("../img/recodes-close.png");
     background-size: 100%;
     top: 27px;
     right: 27px;
     z-index: 1;
     cursor: pointer;
   }
   .recodes{
     display: flex;
     flex-direction: column;
     height: 330px;
     .recodes-head{
       width: 100%;
       height: 60px;
       display: flex;
       align-items: center;
       &>div{
         width: 33%;
         color: #9D9182;
         font-size: 20px;
       }

     }
     .recodes-content{
       width: 100%;
       flex: 1;
       overflow-y: scroll;
       .recodes-box{
         width: 100%;
         height: 100%;
         .recodes-item{
           width: 100%;
           height: 50px;
           display: flex;
           align-items: center;
           &>div{
             width: 33%;
             color: #6C655C;
             font-size: 20px;
           }
           &>div:last-child{
             color: #FF4629;
           }
         }
       }
     }



   }

 }


}

/* 隐藏滚动条，但仍可滚动 */
.scrollable {
  overflow: auto; /* 保持内容可滚动 */
  scrollbar-width: none; /* 对于现代浏览器，隐藏滚动条 */
}

/* 针对IE和Edge的隐藏滚动条样式 */
.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable {
  -ms-overflow-style: none; /* IE 和 Edge 下隐藏滚动条 */
}



</style>
