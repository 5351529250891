import request from "@/utils/request";


//查询是否领取了优惠券
export function couponReceive(params) {
  return request.get("/customer/coupon/is/receive",params);
}

// 活动领取多张优惠券
export function receiveList(data) {
  return request.post("/customer/coupon/receive/list?couponList="+data);
}

//修改客户昵称
export function updateNickName(data) {
  return request.post("/customer/customerUser/updateNickName",data);
}
